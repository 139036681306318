function footerPlacement() {
  const footerWidgets = document.querySelector('.site-footer__col-right');
  if (window.innerWidth < 992) {
    document.querySelector('.site-footer__col-left').appendChild(footerWidgets);
  } else {
    document.querySelector('.site-footer__inner').appendChild(footerWidgets);
  }
}

window.addEventListener('load', footerPlacement);
window.addEventListener('resize', footerPlacement);
